import React from "react"
import { Link } from "gatsby"

import { Nav, NavItem, NavLink } from 'reactstrap';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Teamimage from "./../images/0f6dee8c-c190-4c38-bfb5-06acb0fe88d8.jpg"

const SecondPage = () => (
  <Layout>
    <SEO title="About us" />
    <section
      className="section-header text-white overlay-soft-primary"
      style={{
        backgroundImage: "url(" + Teamimage + ")",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div className="container">
        <div className="row min-vh-50 justify-content-center text-center align-items-center">
          <div className="col-xl-8 col-lg-9 col-md-10">
            <h1 className="display-1">About us</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <Nav className="py-4">
        <NavItem>
          <NavLink href="#">Background</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#goals">Goals</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Mission and Vision</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our values</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our Partners</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our Priorities</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our approach</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Success stories</NavLink>
        </NavItem>
      </Nav>
    </section>
    <section className="section section-lg pt-6 bg-light">
      <div className="container">
        <div className="row justify-content-center mb-5 mb-md-4">
          <div className="col-12 col-md-8 text-center">
            <h4 className="h1 font-weight-bolder mb-4">
              <span className="text-primary">R</span>WANDA{" "}
              <span className="text-secondary">H</span>EALTH{" "}
              <span className="text-warning">I</span>NITIATIVE FOR{" "}
              <span className="text-info">Y</span>OUTH AND{" "}
              <span className="text-danger">W</span>OMEN (RHIYW)
            </h4>
            <p className="lead">
              Rwanda Health Initiative for youth and women is a non-governmental, non-profit organization focusing
              specifically on improving the lives of adolescents, young people and women.
            </p>
          </div>
        </div>
        <div
          id="goals"
          className="row row-grid align-items-center justify-content-between mb-2 mt-2 mb-md-2"
        >
          <div className="col-12 col-md-12">
            {/* Header section with green background */}
            <h2 className="font-weight-bolder mb-4">
              HISTORY AND OVERVIEW
            </h2>
            <p className="lead">
              RHIYW is a Rwandan non-governmental organization born out of the passion and involvement of healthcare
              advocates, public health experts, clinicians (nurses, midwives, and medical doctors), mental health professionals,
              social workers, nutritionists, health educators, health communication specialists, human rights experts, and
              youth SRH champions. In addition to a local team, RHIYW's advisory committee comprises global experts in public
              health, research, and advocacy.
            </p>
            <p className="lead">
              The organization is committed to ensuring that adolescents, young people, and women lead healthy lives by
              providing them with the knowledge to make informed decisions about their health. We prioritize empowerment,
              accessibility, and the quality and comprehensiveness of health services. Our initiatives align with the World Health
              Organization's definition of health, which views health as a state of complete physical, mental, and social well-being,
              not just the absence of disease or infirmity.
            </p>
            <p className="lead">
              The organization implements a variety of projects, including those focused on sexual and reproductive
              health and rights, menstrual health, maternal health, gender-based violence, prevention and management of sexually
              transmitted infections, environmental health, digital health solutions, and the prevention and treatment of both
              communicable and non-communicable diseases, nutrition, mental health and well-being, as well as educational and empowerment programs. Additionally, we address nutrition, health research implementation, and
              other projects aimed at promoting the overall well-being of adolescents, youth, and women, with particular focus
              on the most vulnerable groups within these populations.
            </p>
            <p className="lead">
              RHIYW works closely with public health institutions, including the Rwanda Ministry of Health/Rwanda
              Biomedical Center, Ministry of Youth and Arts, administrative districts, public youth centers, and other national and
              international organizations dedicated to healthcare, health promotion, social well-being, human rights,
              and social
              justice.
            </p>
            <p className="lead">
              RHIYW operates in several districts in Rwanda and has two offices: a satellite office in Musanze District
              in the Northern Province and a headquarters office in Kicukiro District of Kigali.
            </p>
          </div>
        </div>

        <br/>
        <div className="row align-items-center">
          <div className="col-12 col-md-12">

            {/* Section Heading */}
            <h2 className="font-weight-bolder mb-4">RHIYW's Vision, Mission, and Values</h2>

            {/* Vision */}
            {/* Vision */}
            <h3 className="font-weight-bold mb-2">Vision</h3>
            <p className="lead">
              To improve the health and well-being of adolescents, youth, and women by ensuring access to comprehensive,
              high-quality healthcare, education, empowerment, and support.
            </p>

            {/* Mission */}
            <h3 className="font-weight-bold mb-2">Mission</h3>
            <p className="lead">
              Strengthening institutional capacity to promote the health and well-being of adolescents, youth, and women, and
              providing them with the knowledge, resources, and support needed to live healthy lives.
            </p>

            {/* Values */}
            <h3 className="font-weight-bold mb-2">Values</h3>
            <p className="lead mb-2">The values upheld by RHIYW include:</p>
            <ol className="list-decimal pl-5 lead space-y-1">
              <li>Gender equality</li>
              <li>Equity</li>
              <li>Accountability</li>
              <li>Partnership</li>
              <li>Transparency</li>
              <li>Sustainability</li>
              <li>Integrity</li>
              <li>Creativity & Innovation</li>
              <li>Excellence</li>
              <li>Empowerment</li>
              <li>Inclusivity</li>
              <li>Collaboration</li>
              <li>Innovation</li>
              <li>Compassion</li>
              <li>Respect</li>
              <li>Age appropriate</li>
              <li>Culture sensitivity</li>
              <li>Adaptability</li>
            </ol>
            <br/>
            {/* Priorities */}
            <h3 className="font-weight-bold mb-2">RHIYW's Priorities</h3>
            <p className="lead mb-2">
              RHIYW's priorities include:
            </p>
            <ol className="list-decimal pl-5 lead space-y-1">
              <li>
                To strengthen the capacity of public institutions, including health facilities, youth centers, schools,
                Isange One Stop Centers, and others, in delivering high-quality, affordable health and well-being
                services,
                with a particular focus on sexual and reproductive health (SRH) services.
              </li>
              <li>
                Increasing access to essential health and wellness services and information, particularly in underserved
                or vulnerable communities.
              </li>
              <li>
                Conducting research, monitoring, and evaluation programs to inform health policies and practices.
              </li>
              <li>
                Leverage technology and digital platforms to enhance the accessibility of health and wellness services.
              </li>
              <li>
                Supporting the development of evidence-based clinical guidelines for various health services.
              </li>
              <li>
                Ensuring the availability of essential health supplies and equipment in public health facilities, youth
                centers, and other service provision areas.
              </li>
              <li>
                Strengthening the leadership capabilities of youth and women in health and well-being programs.
              </li>
              <li>
                Enhancing the capacity of youth peers/volunteers, community health workers, and champions for women in
                delivering health and well-being education and services.
              </li>
              <li>
                Supporting public health facilities, youth centers, and other institutions with the necessary tools and
                resources for health and wellness services.
              </li>
              <li>
                Promoting mental health support, resilience-building, and healthy lifestyle initiatives.
              </li>
              <li>
                Strengthening strategies and programs to enhance women’s health during pregnancy and childbirth,
                especially
                for young women.
              </li>
              <li>
                Ensure access to prevention, testing, and treatment services for sexually transmitted infections,
                communicable and non-communicable diseases, and support education campaigns around the prevention of these
                diseases.
              </li>
              <li>
                Implement community-level interventions to combat malnutrition and promote food security.
              </li>
              <li>
                Ensure that youth have access to education, health, and wellness services that enable them to live safe,
                healthy lives, free from discrimination and violence.
              </li>
            </ol>
          </div>
        </div>


      </div>
    </section>

    <section className="section section-lg pt-6">
      <div className="container flex flex-col gap-8">
        <div className="row row-grid align-items-center">
          <div className="col-12">
            <h2 className="font-weight-bolder mb-4">Partners</h2>
            <h3 className="font-weight-bold mb-2">Partners</h3>
            <p className="lead">
              <div className="row">
                <div className="col">
                  <ul className="list-disc pl-5">
                    <li>Rwanda Ministry of Health (MoH)</li>
                    <li>Rwanda Biomedical Center (RBC)</li>
                    <li>Imbuto Foundation</li>
                    <li>Ministry of Youth and Art</li>
                    <li>Districts / District Joint Action Development Forum (JADF) / Public Youth Centers</li>
                    <li>Bixby Center, University of California Berkeley</li>
                  </ul>
                </div>
                <div className="col">
                  <ul className="list-disc pl-5">
                    <li>The David and Lucile Packard Foundation</li>
                    <li>Rwanda Society of Obstetricians and Gynecologists (RSOG)</li>
                    <li>Royal College of Obstetricians and Gynecology (RCOG)</li>
                    <li>IPAS Africa Alliance</li>
                    <li>KASHA</li>
                    <li>Preston Werner Ventures</li>
                  </ul>
                </div>
                <div className="col">
                  <ul className="list-disc pl-5">
                    <li>Venture Strategies for Health and Development (VSHD)</li>
                    <li>Hesperian Health Guides</li>
                    <li>Great Lakes Initiatives for Human Rights and Development (GLIHD)</li>
                    <li>Action of Men for Gender Equality in Rwanda (AMEGERWA)</li>
                    <li>Nurses and Midwives Professional Organizations</li>
                    <li>Troupe des Personnes Handicapées Twuzuzanye, Rwanda (THT)</li>
                  </ul>
                </div>
              </div>
            </p>
          </div>
        </div>
        <br/>
        <div className="row gap-8">

          <div className="col-12 ">
            <h2 className="font-weight-bolder mb-4">Our Approach</h2>
            <p className="lead">
              RHIYW implements a variety of strategies to effectively promote health and wellbeing services.
              <ol className="list-decimal pl-5 mt-2">
                <li>Strengthening institutional capacity</li>
                <li>Empowerment through education</li>
                <li>Mental health and resilience-building</li>
                <li>Research and evidence-based practices</li>
                <li>Establishing strong partnerships and supporting public institutions</li>
                <li>Focus on hard-to-reach populations</li>
                <li>Community engagement and participation</li>
                <li>Youth involvement and implementing peer education</li>
                <li>Technology and innovation; leveraging digital health solutions</li>
                <li>Employing a client-centered approach; youth-friendly and women-centered approach</li>
                <li>Advocacy and policy influence</li>
                <li>Holistic care models</li>
                <li>Advocacy and policy engagement</li>
                <li>Collaborating with national and international public health research institutions</li>
                <li>Collaboration with professional organizations</li>
                <li>Implementing robust monitoring and evaluation frameworks to assess program effectiveness, gather
                  feedback, and make data-informed adjustments
                </li>
                <li>Culturally sensitive services and nondiscrimination practices</li>
                <li>Promoting healthy lifestyles</li>
                <li>Mental health and resilience-building</li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage
